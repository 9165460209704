import PageContainer from "../components/PageContainer";

export default function InstallScreen() {
	return (
		<PageContainer>
			<h3>Installing Bridgeway Help</h3>
			Installing Bridgeway Help on your iPhone is easy to do.
			<br/>
			Tap on <img src="/assets/img/ios-share.png" title="iOS Share Button" style={{ width: "16px" }} /> and tap <strong>Add to Home Screen</strong>
		</PageContainer>
	);
}