import { IconDefinition } from '@fortawesome/fontawesome-common-types'

const Engage: IconDefinition = {
	// @ts-ignore
	prefix: "",
	// @ts-ignore
	iconName: "engage",
	icon: [
		446, // Width
		446, // Height
		["", ""], // Ignore
		"", // Ignore
		"m445.61,254.24H103.1c4.94,30.21,18.16,54.22,39.64,72.05,21.48,17.84,48.88,26.75,82.21,26.75,39.84,0,74.07-13.92,102.71-41.79l89.82,42.18c-22.39,31.77-49.21,55.26-80.45,70.49-31.24,15.23-68.35,22.85-111.3,22.85-66.65,0-120.94-21.02-162.86-63.07C20.96,341.66,0,289,0,225.73S20.89,107.08,62.68,64.24C104.47,21.42,156.86,0,219.87,0c66.91,0,121.32,21.42,163.25,64.24,41.92,42.83,62.88,99.39,62.88,169.69l-.39,20.31Zm-106.62-83.97c-7.04-23.69-20.93-42.96-41.67-57.8-20.74-14.84-44.79-22.26-72.18-22.26-29.73,0-55.81,8.34-78.24,24.99-14.08,10.42-27.12,28.77-39.12,55.07h231.2Z",
	],
};
export default Engage;