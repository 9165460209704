import "./sentry";
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/main.less'
import { CustomProvider } from 'rsuite';
import { RouteObject, RouterProvider, createHashRouter, createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom';
import SearchScreen from './screens/SearchScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import ItemScreen from './screens/ItemScreen';
import HomeScreen from './screens/HomeScreen';
import Util from './Util';
import ProcessedItem from './interfaces/ProcessedItem';
import InstallScreen from "./screens/InstallScreen";


const root = document.getElementById("root");

const getRoutes = (item: ProcessedItem) => {
	let children: RouteObject[] = [];
	const path = Util.slugify(item.name);

	if (typeof item.items !== "undefined") {
		for (let child of item.items) {
			if (typeof child.items !== "undefined") {
				children.push(getRoutes(child));
			}
		}
	}

	const routeObject: RouteObject = {
		path,
		element: <ItemScreen item={item} />,
	};

	if (children.length > 0) {
		routeObject.children = [
			{
				index: true,
				element: <ItemScreen item={item} />
			},
			...children,
		];
		routeObject.element = undefined;
	}

	return routeObject;
}

const routes: RouteObject[] = [
	{
		path: "/",
		element: (<CustomProvider theme='dark'><App /></CustomProvider>),
		children: [
			{
				path: "",
				element: <HomeScreen />,
			},
			{
				path: "search",
				element: <SearchScreen />
			},
			{
				path: "install",
				element: <InstallScreen />,
			},
			...Util.getConfig().map((val) => {
				return getRoutes(val);
			}),
			{
				path: "*",
				element: <NotFoundScreen />
			},
		]
	}
];

console.log({ routes })
const router = createHashRouter(routes);

if (root) {
	const reactRoot = createRoot(root);
	reactRoot.render(
		<RouterProvider router={router} />
	);
}

// Install our service worker.
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register("/worker.js")
		.catch(e => {
			console.error("Failed to install service worker!", e);
		});
}