import Tile from "../components/Tile";
import PageContainer from "../components/PageContainer";
import Styled from "styled-components";
import Config from "../Config";

const TileContainer = Styled(PageContainer)`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: center;
`;

export default function HomeScreen() {
	return (
		<TileContainer>
			{Config.map((item, ind) => {
				return (<Tile
					item={item}
					alt={item.description}
					icon={item.icon}
					title={item.name}
					to={( item.link ? item.link : `/${item.name.toLowerCase().split(" ").join("-")}` )}
					key={ind}
					target={item.target}
				/>);
			})}
		</TileContainer>
	);
}