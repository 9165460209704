import { Divider, Input, InputGroup } from "rsuite";
import PageContainer from "../components/PageContainer";
import CommonIcon from "../components/common/CommonIcon";
import { useEffect, useMemo, useRef, useState } from "react";
import WideTile from "../components/WideTile";
import Fuse from 'fuse.js';
import Util from "../Util";
import ProcessedItem from "../interfaces/ProcessedItem";

export default function SearchScreen() {
	const [searchTerms, setSearchTerms] = useState<string>("");
	const searchBox = useRef<HTMLInputElement>(null);

	const results = useMemo(() => {
		const fuse = new Fuse(Util.allConfigItems().filter(i => !i.disabled), {
			threshold: 0.1,
			keys: [
				"name",
				"description",
				"keywords"
			]
		});

		// Stupidly simple way to remove duplicates.
		const hashTable: { [key: string]: ProcessedItem } = {};

		const results = fuse.search(searchTerms)
		.map(r => r.item)
		.sort((a, b) => a.name.localeCompare(b.name));

		for (let result of results) {
			hashTable[result.slug] = result;
		}

		return Object.values(hashTable);
	}, [ searchTerms ]);

	useEffect(() => {
		if (searchBox.current) {
			searchBox.current.focus();
		}
	}, []);

	return (
		<PageContainer>
			<div>
				<InputGroup style={{ maxWidth: "500px", margin: "0 auto" }}>
					<Input
						placeholder="Enter your search here..."
						value={searchTerms}
						onChange={setSearchTerms}
						ref={searchBox}
					/>
					<InputGroup.Button
					>
						<CommonIcon icon="search" />
					</InputGroup.Button>
				</InputGroup>
			</div>
			<div>
				{results.map( (item, ind) => {
					const link = item.link || "/" + item.path;
					return (
						<WideTile
							to={link}
							icon={item.icon}
							title={item.name}
							description={item.description}
							disabled={item.disabled}
						/>
					);
				})}
				{results.length <= 0 && <div style={{ padding: "20px", fontStyle: "italic" }}>
					No results	
				</div>}
				{searchTerms === "version" && <div style={{
					backgroundColor: "rgba(0, 0, 0, .5)",
					padding: "20px",
					borderRadius: "10px",
					display: "inline-block",
					width: "500px"
				}}>
					<div style={{ display: "inline-block", paddingRight: "25px", width: "150px" }}>
						<strong>Version</strong>
						<br/>
						{__VERSION__}
					</div>
					<div style={{ display: "inline-block", width: "150px" }}>
						<strong>Commit SHA</strong>	
						<br/>
						{__COMMIT_HASH__}
					</div>
				</div>}
			</div>
		</PageContainer>
	);
}