import { IconButton, Modal } from "rsuite";
import { useApp } from "../App";
import CommonIcon from "../components/common/CommonIcon";
import { useEffect, useState } from "react";

interface State {
	appVersion: string,
	workerVersion: string,
}

export default function VersionModal() {
	const app = useApp();

	const [state, setState] = useState<State>({
		appVersion: "TBD",
		workerVersion: "TBD",
	});

	const onClose = () => {
		app.showModal("");
	}

	useEffect(() => {
		if (__VERSION__) {
			setState(s => ({
				...s,
				appVersion: __VERSION__,
			}));
		}

		app.getWorkerVersion()
		.then(ver => {
			setState(s => ({
				...s,
				workerVersion: ver,
			}))
		});
	}, []);

	return (
		<Modal open={(app.currentModal === "version")} onClose={onClose} size="xs">
			<Modal.Header>Bridgeway Help Version</Modal.Header>
			<Modal.Body>
				<strong>App Version:</strong> {state.appVersion}
				<br/>
				<strong>Worker Version:</strong> {state.workerVersion}
			</Modal.Body>
			<Modal.Footer>
				<IconButton
					appearance='primary'
					icon={<CommonIcon icon="close" />}
					onClick={onClose}
				>Close</IconButton>
			</Modal.Footer>
		</Modal>
	);
}