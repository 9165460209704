import { useNavigate } from "react-router";
import CommonIcon, { IconList } from "./common/CommonIcon";
import Styled from 'styled-components';
import { useApp } from "../App";

const Container = Styled.div<{ disabled?: boolean }>`
	margin: 10px;
	border-radius: 10px;

	backdrop-filter: blur(10px);
	box-shadow: 3px 3px 10px rgba(17, 17, 17, .5);
	
	transition: transform linear .05s;
	

	display: flex;
	text-align: left;

	${p => !p.disabled && `
		background-color: rgba(0, 130, 149, .6);
		color:white;
		cursor: pointer;

		&:hover {
			transform: scale(1.025);
			background-color: rgba(0, 130, 149, .8);
		}
	`}
	${p => p.disabled && `
		color: grey;
		cursor: not-allowed;
	`}

	.icon {
		font-size: 30pt;
		padding-left: 10px;
		&.compact {
			margin-top:-8px;
		}
	}
	.title {
		font-size: 17pt;
	}
	.detail {
		flex: 1;
		padding: 10px;
		padding-left: 20px;
	}
`;

interface TileProps {
	to: string,
	title: string,
	description?: string,
	icon: IconList
	disabled?: boolean,
	target?: "_blank" | "_self",
}

export default function WideTile(props: TileProps) {
	const navigate = useNavigate();
	const app = useApp();

	const onClick = () => {
		if (props.disabled) {
			return;
		}

		if (props.to.toLocaleLowerCase().startsWith("http")) {
			if (props.target && props.target === "_blank") {
				window.open(props.to, "_blank");
			} else {
				window.location.href = props.to;
			}
		} else if (props.to.toLocaleLowerCase().startsWith("modal://")) {
			const url = new URL(props.to);
			console.log(`Opening modal URL`, url, props.to);
			app.showModal(url.host);
		} else {
			navigate(props.to);
		}
	}

	return (
		<Container onClick={onClick} disabled={props.disabled}>
			<div className={(props.description ? "icon" : "icon compact")}>
				<CommonIcon icon={props.icon} style={{ color: (props.icon === "heart" && "#E74C3C" || "inherit") }} />
			</div>
			<div className="detail">
				<div className="title">{props.title}</div>
				{props.description && <div className="description">{props.description}</div>}
			</div>
		</Container>
	);
}