import { IconDefinition } from '@fortawesome/fontawesome-common-types'

const multi = 1;

const Wellbeing: IconDefinition = {
	// @ts-ignore
	prefix: "",
	// @ts-ignore
	iconName: "wellbeing",
	icon: [
		43 * multi, // Width
		43 * multi, // Height
		["", ""], // Ignore
		"", // Ignore
		[
			"M22.8,1.3c-3.2,6.3-4.6,12.9-3.3,20.2.6-.6,1-1,1.4-1.4,2.2-2.3,4.8-4,7.8-5.1,1.5-.6,3-1,4.8-.8,0,.1-.1.3-.2.4-4.7,2.3-8,6-10,10.8-.3.8-.7,1.3-1.7,1.5-2.4-1-4.6-2.5-6.8-4.1-.4-6.8,1.7-12.8,5.2-18.5,1.5-2.5,1.8-2.6,2.9-3Z",
			"M39,7.3c1.5,0,1.6,1.1,2.2,1.8.6,3.1-.8,5.5-2.5,7.8-3.1,4.2-7.1,7.3-11.5,10-3.6,2.2-7.3,4-11.4,5.3-2.6.8-5.3,1.3-8.1,1.2-1.3,0-2.5-.1-3.6-1-.3-.2-.7-.3-1-.5-.6-.6-1-1.3-.6-2.3,2.2,2.1,4.8,2.3,7.7,1.9,4.2-.5,8-1.9,11.7-3.8,5.1-2.5,9.8-5.7,13.6-9.9,1.6-1.8,3.1-3.7,3.9-6.1.5-1.5.4-2.9-.4-4.4Z",
			"M4.7,6.9c-1.9,2.4-1.2,4.6,0,6.7,1.5,2.7,3.6,5,5.9,7,2.9,2.5,6,4.7,9.5,6.4.1,0,.2.2.4.4-.6.6-1.2.8-2.1.8-5.2-2.9-10-6.4-13.8-11.3-1.1-1.4-2-3-2.5-4.7-.6-2.2-.2-3.8,1.5-5,.2-.1.5-.2,1-.3Z",
			"M24.4,15.1c-2.8-.4-3.7-2.6-3.5-4.2.2-1.8,1.8-3,3.6-3,1.6,0,3.2,1.3,3.4,3,.2,1.8-.7,3.8-3.6,4.2Z",
			"M16.5,32.8c1.7-.7,3.3-1.3,5.2-2.1,0,1.4-.2,2.7-.2,4,0,1.3.2,2.6.4,3.8.2,1.3.6,2.5.7,3.8-2.8-2.7-4.7-5.9-6.2-9.6Z",
			"M22.8,30.3c1-1,2-1.1,3.2-.6,2.5,1,5.1,1.7,7.8,1.9,2.6.2,5.1,0,7-2.2.3,1.3.2,1.8-.7,2.5-1,.8-2.2,1.2-3.4,1.3-4.9.4-9.4-1-13.9-3Z",
			"M17.3,7.5c-.2.4-.3.7-.5,1.1-1.8-.3-3.5-.6-5.2-.9-1.6-.2-3.2.1-4.7.9-.6.3-1,1-1.6,1.6-.4-.5-.3-.9,0-1.4.8-1.5,2-2.4,3.7-2.6,2.8-.2,5.5-.1,8.1.9.1,0,.2.1.3.3Z",
			"M38.1,9.7c-2.1-1.8-4.3-2.3-6.7-1.8-1.3.2-2.5.3-3.8.5-.4-.3-.8-.6-1.2-.9.1-.2.2-.4.3-.4,2.5-.8,5-.8,7.6-.8,1.6,0,2.6,1,3.5,2.1.3.3.2.9.3,1.3Z",
			"M6.2,20c.5.5.8.8,1.2,1.1-.3.5-.5.8-.8,1.2-1.1,1.6-2,3.3-1.8,5.4,0,.6.3,1.2.4,1.9-2.3-1.2-2.4-3.3-1.5-5.5.5-1.4,1.5-2.6,2.4-4.1Z",
			"M36,21.1c.2-.3.5-.6.8-.9.2,0,.3,0,.4,0,1.3,1.5,2.3,3.2,2.8,5.2.4,1.9-.5,3.5-2.5,4.2,1.7-1.6,1.5-3.3.6-5-.6-1.2-1.3-2.3-2-3.6Z",
			"M15.5,29.4c-.2-.8-.3-1.4-.5-2.4,1.1.6,1.8,1.1,2.8,1.6-.7.5-1.4.8-2.3.8Z"
		].join(""),
	],
};
export default Wellbeing;