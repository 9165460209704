import ConfigCategory from "./interfaces/ConfigItem";

const config: ConfigCategory[] = [
	{
		name: "Need Something?",
		icon: "handsHoldingChild",
		description: "IT Support\nAcommodation\nBook a toll charge\nPersonal Issue",
		items: [
			{
				name: "IT Support",
				description: "Request IT Support or Equipment",
				icon: "display",
				link: "https://support.bridgeway.consulting",
				keywords: [ "computer" ]
			},
			{
				name: "Accommodation",
				description: "Request accommodation",
				icon: "hotel",
				link: "https://bss.bridgeway.consulting",
				keywords: [ "hotel" ],
			},
			{
				name: "Book a toll charge",
				description: "Book a charge for a toll crossing or zone",
				icon: "roadBarrier",
				link: "https://tolls.bridgeway.consulting",
				disabled: false,
				keywords: [ "crossing", "zone", "ulez" ],
			},
			{
				name: "Personal Issue",
				description: "PPE and Equipment Requests",
				icon: "peopleGroup",
				link: "#",
				disabled: true,
				keywords: [ "ppe" ]
			},
		],
	}, 
	{
		name: "Report Something?",
		icon: "exclamationTriangle",
		items: [
			{
				name: "Close Call",
				description: "Report a close call",
				icon: "exclamationTriangle",
				link: "https://closecall.bridgeway.consulting",
			},
			{
				name: "Commercial Close Calls",
				description: "Report commercial close calls",
				icon: "coins",
				link: "https://ccc.bridgeway.consulting",
			},
			{
				name: "Equipment Defect (D.I.R.T)",
				description: "Report a defect with Bridgeway Equipment",
				icon: "tools",
				link: "https://dirt.bridgeway.consulting",
			},
			{
				name: "Facilities Issue",
				description: "Report a facilities issue.",
				icon: "houseCrack",
				link: "https://facilities.bridgeway.consulting",
			},
			{
				name: "Online Accident Book",
				description: "Report an accident via the First Aid reporting tool.",
				icon: "personFalling",
				link: "https://firstaid.bridgeway.consulting",
			},
			{
				name: "Vehicle Defect",
				description: "Report a defect with a Bridgeway Vehicle",
				icon: "van",
				link: "https://fm.bridgeway.consulting",
			},
			{
				name: "Site Logs - DAS",
				description: "Site Logs & Reporting Tools",
				icon: "fileSignature",
				link: "#",
				disabled: true,
			},
			{
				name: "Vehicle Accident",
				description: "Report an accident in a Bridgeway Vehicle",
				icon: "carAccident",
				link: "#",
				disabled: true,
			},
			
		],
	},
	{
		name: "Useful Information",
		icon: "infoCircle",
		items: [
			{
				name: "Assurance",
				icon: "helmetSafety",
				items: [
					{
						name: "Company Policies",
						description: "Company Policies",
						icon: "book",
						link: "https://resources.bridgeway.consulting/resource/view/6",
					},
					{
						name: "Safety Documents",
						description: "Useful documents",
						icon: "infoCircle",
						link: "https://resources.bridgeway.consulting/resource/view/13",
					},
				]
			},
			{
				name: "Fleet",
				description: "",
				icon: "van",
				items: [
					{
						name: "Fleet Documents",
						description: "View Fleet document library",
						icon: "folder",
						link: "https://resources.bridgeway.consulting/resource/view/3",
					},
					{
						name: "Fuel Card Petrol Stations",
						description: "Find stations that support our fuel cards.",
						icon: "creditCard",
						link: "https://www.erouteonline.com/v7/ukfuels/"
					},
				],
			},
			{
				name: "Shared Documents",
				icon: "openfolder",
				link: "https://resources.bridgeway.consulting/resource",
			},
			{
				name: "Company Policies",
				description: "Company Policies",
				icon: "book",
				link: "https://resources.bridgeway.consulting/resource/view/6",
			}
		],
	},
	{
		name: "Useful Links",
		icon: "link",
		items: [
			{
				name: "BMS",
				description: "Bridgeway Management System",
				icon: "tableColumns",
				link: "https://bms.bridgeway.consulting"
			},
			{
				name: "Company Website",
				description: "Bridgeway Main Website",
				icon: "gears",
				link: "https://bridgeway-consulting.co.uk",
			},
			{
				name: "Engage Online",
				description: "Engage Online staff portal",
				icon: "engage",
				link: "https://engage.bridgeway.consulting",
				keywords: [ "timesheet", "address", "details", "bank" ],
			},
			{
				name: "Enplan",
				description: "Enplan Shift Planning System",
				icon: "calendar",
				link: "https://enplan.bridgeway.consulting",
				keywords: [ "shift", "planning" ]
			},
			{
				name: "OnCall Info",
				description: "OnCall Information Sheet",
				icon: "infoCircle",
				link: "https://bridgewayconsultinglimited.sharepoint.com/:x:/r/sites/FileSharing/_layouts/15/doc2.aspx?sourcedoc=%7B05102F87-7A0F-4207-9BC1-D1E18469FEAA%7D&file=OCM%20BCL%20Information.xlsx&action=default&mobileredirect=true&cid=F0CA2198-EE55-4EF2-AF24-3583B2024101",
			},
			{
				name: "Rulebooks",
				description: "Track Workers Manual and Rulebook",
				icon: "book",
				link: "https://resources.bridgeway.consulting/resource/view/13?path=Rulebook",
				keywords: [ "track", "manual", "rule", "book", "rulebook" ],
			},
			{
				name: "Staff Suggestions",
				description: "Suggest ideas and changes within Bridgeway",
				icon: "comments",
				link: "https://engage.bridgeway.consulting/staffsuggestions.aspx"
			},
			{
				name: "Site Assistant",
				description: "Plan and record mentored site visits",
				icon: "train",
				link: "https://siteassistant.bridgeway.consulting"
			},
			{
				name: "Progession",
				description: "Employee Project Progression Tracking",
				icon: "clipboard",
				link: "#",
				disabled: true,
			},
			{
				name: "Bridgeway Webmail",
				description: "Online email for bridgewayemail.com",
				icon: "envelope",
				link: "https://webmail.bridgewayemail.com",
				disabled: false,
			},
		],
	},
	{
		name: "Media",
		icon: "film",
		link: "https://videos.bridgeway.consulting",
		items: [
			{
				name: "Bridgeway Podcasts",
				description: "Listen to Bridgeway Podcasts",
				icon: "microphone",
				link: "https://bridgeway-consulting.co.uk/our-podcasts",
			},	
			{
				name: "Site Tools Videos",
				description: "Instructional and information videos",
				icon: "film",
				link: "https://videos.bridgeway.consulting/#/category/toolbox-talks",
			},
			{
				name: "BSS Videos",
				description: "Instructional and information videos",
				icon: "film",
				link: "https://videos.bridgeway.consulting/#/category/bss",
			},	
			{
				name: "Fleet Videos",
				description: "Information and instructional videos",
				icon: "film",
				link: "https://videos.bridgeway.consulting/#/category/fleet",
			},

		],
	},
	{
		name: "Wellbeing",
		icon: "wellbeing",
		items: [
			{
				name: "Validium",
				description: "Confidential employee support line",
				icon: "validium",
				link: "modal://validium",
			},	
			{
				name: "Wellbeing Champions",
				description: "Bridgeway Health & Wellbeing Champions",
				icon: "wellbeing",
				link: "https://engage.bridgeway.consulting/wellbeing-champions.aspx",
			},
		],
	}
];
export default config;