import { IconButton } from "rsuite";
import PageContainer from "../components/PageContainer";
import CommonIcon from "../components/common/CommonIcon";
import { useNavigate } from "react-router";

export default function NotFoundScreen() {
	const navigate = useNavigate();

	const goHome = () => {
		navigate("/");
	}

	return (
		<PageContainer>
			<div style={{ fontSize: "60pt", filter: "grayscale(1)", textShadow: "5px 5px 5px rgba(0, 0, 0, .5)" }}>🔎</div>
			<h1>Not Found</h1>
			<br/>
			The page you're looking for can't be found!
			<br/><br/>
			<IconButton onClick={goHome} icon={<CommonIcon icon="home" />} size="lg" appearance="primary" >Home</IconButton>
		</PageContainer>
	);
}