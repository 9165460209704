import { useMemo } from "react";
import PageContainer from "../components/PageContainer";
import WideTile from "../components/WideTile";
import ProcessedItem from "../interfaces/ProcessedItem";

interface Props {
	item: ProcessedItem,
}

export default function ItemScreen(props: Props) {

	const enabledItems = useMemo(() => {
		if (!props.item.items) {
			return [];
		}

		return props.item.items.filter(i => !i.disabled).sort((a, b) => {
			return a.name.localeCompare(b.name);
		})
	}, [ props.item.items ]);

	const disabledItems = useMemo(() => {
		if (!props.item.items) {
			return [];
		}

		return props.item.items.filter(i => i.disabled).sort((a, b) => {
			return a.name.localeCompare(b.name);
		})
	}, [ props.item.items ]);

	return (
		<PageContainer>
			<div style={{
				padding: "10px",
				backgroundColor: "rgba(0, 0, 0, .25)",
				borderRadius: "5px",
			}}>
				<div style={{ padding: "5px", fontSize: "20pt" }}>{props.item.name}</div>
				{props.item.description}
			</div>
			{enabledItems.map((item, ind) => {
				let to = item.link || item.slug;
				return (
					<WideTile
						key={ind}
						to={to}
						icon={item.icon}
						title={item.name}
						description={item.description}
						disabled={item.disabled}
						target={item.target}
					/>
				);
			})}
			{disabledItems.map((item, ind) => {
				let to = item.link || item.slug;
				return (
					<WideTile
						key={ind}
						to={to}
						icon={item.icon}
						title={item.name}
						description={item.description}
						disabled={item.disabled}
						target={item.target}
					/>
				);
			})}
		</PageContainer>
	);
}