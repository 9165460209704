import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { Icon } from '@rsuite/icons';
import { IconProps } from '@rsuite/icons/lib/Icon';
import React from 'react';

type BaseProps = IconProps & React.RefAttributes<SVGElement>;

interface Props extends Omit<BaseProps, 'as'> {
	as: IconDefinition,
	size?: "1em" | "2em" | "3em" | "4em" | "5em" | string,
}

interface SvgIconProps  {
	faIcon: IconDefinition,
	size?: "1em" | "2em" | "3em" | "4em" | "5em" | string,
}

const SvgIcon = ({ faIcon, ...rest }: SvgIconProps & React.SVGProps<SVGSVGElement>) => {
	const [ width, height, ligatures, unicode, svgPathData ] = faIcon.icon;
	let svgData = "";

	if (Array.isArray(svgPathData)) {
		svgData = svgPathData.join(" ");
	} else {
		svgData = svgPathData;
	}

	return (
	  <svg {...rest} viewBox={`0 0 ${width} ${height}`} width={(rest.size || "1em")} height={(rest.size || "1em")} fill="currentColor">
		<path d={svgData}></path>
	  </svg>
	);
};

export default function FaIcon(props: Props) {
	return (

		<Icon
			{...props}
			as={SvgIcon}

			//@ts-ignore
			faIcon={props.as}
		/>
	);
}