import Styled from 'styled-components';
import CommonIcon, { IconList } from './common/CommonIcon';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import ConfigItem from '../interfaces/ConfigItem';
import { useApp } from '../App';

const TileContainer = Styled.div<{ disabled?: boolean }>`
	margin: 10px;
	display: inline-block;
	width: 125px;

	${p => !p.disabled && `
		&:hover {
			.tile {
				transform: scale(1.1);
				background-color: rgba(0, 130, 149, .8);
			}
		}
	`}


	.tile {
		width: 125px;
		height: 125px;
		border-radius: 20px;
	
		backdrop-filter: blur(10px);
		box-shadow: 3px 3px 10px rgba(17, 17, 17, .5);
	
		font-size: 55pt;
		text-align: center;

		transition: transform linear .05s;

		cursor: pointer;

		${p => !p.disabled && `
			color: white;
			background-color: rgba(0, 130, 149, .6);
		`}
		${p => p.disabled && `
			color: grey;
			cursor: not-allowed;
		`}
	}
	.title {
		padding: 10px;
		text-align: center;
		font-size: 16pt;
		text-shadow: 3px 3px 10px rgba(17, 17, 17, .5);
	}
`;

interface TileProps {
	icon: IconList,
	title: string,
	to: string,
	disabled?: boolean,
	alt?: string,
	item: ConfigItem,
	target?: "_blank" | "_self",
}

export default function Tile(props: TileProps) {
	const app = useApp();
	const navigate = useNavigate();

	const onClick = () => {
		if (props.disabled) {
			return;
		}
		if (props.to.toLocaleLowerCase().startsWith("http")) {
			if (props.item.target === "_blank") {
				window.open(props.to, "_blank");
			} else {
				window.location.href = props.to;
			}
		} else if (props.to.toLocaleLowerCase().startsWith("modal://")) {
			const url = new URL(props.to);
			console.log(`Opening modal URL`, url, props.to);
			app.showModal(url.host);
		} else {
			navigate(props.to);
		}
	}

	const title = useMemo(() => {
		const items: string[] = [];
		if (props.item.items) {
			for (let item of props.item.items) {
				if (item.disabled) {
					continue;
				}
				items.push(item.name);
			}
		}

		return items.join('\n');
	}, [ props.item ]);

	return (
		<TileContainer onClick={onClick} disabled={props.disabled} title={title}>
			<div className='tile'>
				<CommonIcon icon={props.icon} style={{ color: (props.icon === "heart" && "#E74C3C" || "inherit") }} />
			</div>
			<div className='title'>
				{props.title}
			</div>
		</TileContainer>
	);
}