import { IconButton, Modal } from "rsuite";
import { useApp } from "../App";
import CommonIcon from "../components/common/CommonIcon";

export default function ValidiumModal() {
	const app = useApp();

	const onClose = () => {
		app.showModal("");
	}
	const placeCall = () => {
		window.location.href = "tel:+448003584858";
	}

	return (
		<Modal open={(app.currentModal === "validium")} onClose={onClose} size="xs">
			<Modal.Header>Speak with Validium?</Modal.Header>
			<Modal.Body>
				Validium provides <strong>confidential</strong> employee support and advice without involving anyone from work.
				Anything discussed is <strong>confidential</strong> between yourself and them.
				<br/><br/>
				You can speak to them over the phone or online.
				<br/>
				To speak to them online you can sign up on their portal:
				<br/>
				<br/>
				<center>
					<IconButton
						icon={<CommonIcon icon="validium" />}
						color="violet"
						onClick={() => {
							window.open("https://wellbeing.hub.healthhero.com/Identity/Account/Register", "_blank");
						}}
					>Validium Portal</IconButton>
				</center>

				<br/>

				Enter <strong>Bridgeway</strong> for the username and <strong>eap4support</strong> for the password.

				<br/><br/>

				If you prefer talking to someone, you can give them a call on 0800 358 4858 or click the <strong>Call</strong> button below.

			</Modal.Body>
			<Modal.Footer>
				<IconButton
					icon={<CommonIcon icon="phone" />}
					onClick={placeCall}
				>Call</IconButton>
				<IconButton
					appearance='primary'
					icon={<CommonIcon icon="close" />}
					onClick={onClose}
				>Cancel</IconButton>
			</Modal.Footer>
		</Modal>
	);
}