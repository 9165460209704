import UA from 'ua-parser-js';
import ConfigCategory from './interfaces/ConfigItem';
import ProcessedItem from './interfaces/ProcessedItem';
import config from './Config';

export default class Util {
	

	static getDisplayMode() {
		const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
		if (document.referrer.startsWith('android-app://')) {
			return 'twa';
		} else if ((navigator as any).standalone || isStandalone) {
			return 'standalone';
		}
		return 'browser';
	}
	static isApple() {
		const ua = UA(navigator.userAgent);
		if (ua.device.vendor === "Apple") {
			return true;
		}
		return false;
	}

	// Credit: https://www.30secondsofcode.org/js/s/string-to-slug/
	static slugify(str: string) {
		return str
		  .toLowerCase()
		  .trim()
		  .replace(/[^\w\s-]/g, '')
		  .replace(/[\s_-]+/g, '-')
		  .replace(/^-+|-+$/g, '');
	}

	static flattenProcessedItems(items: ProcessedItem[]): ProcessedItem[] {
		let allItems = items;
		
		for (let item of items) {
			if (item.items) {
				for (let subItem of item.items) {
					allItems.push(subItem);
					if (subItem.items) {
						const children = this.flattenProcessedItems(subItem.items);
						for (let child of children) {
							allItems.push(child);
						}
					}
				}
			}
		}

		return allItems;
	}

	// Grabs everything as a flattened array
	static allConfigItems(): Omit<ProcessedItem, 'items'>[] {
		return this.flattenProcessedItems(this.getConfig());
	}

	static processItems(parent: null | ProcessedItem, items: ConfigCategory[]): ProcessedItem[] {
		const processedItems: ProcessedItem[] = [];

		for (let item of items) {
			const slug = this.slugify(item.name);

			const pathParts = [];
			if (parent) {
				pathParts.push(parent.path);
			}
			pathParts.push(slug);

			const path = pathParts.join("/");


			const processed: ProcessedItem = {
				...item,
				slug,
				path,
				parent,
				items: undefined,
			};

			if (item.items) {
				processed.items = this.processItems(processed, item.items);
			}

			processedItems.push(processed);

		}

		return processedItems;
	}

	static getConfig(): ProcessedItem[] {
		return this.processItems(null, config);
	}

	static getConfigChain(item: ProcessedItem): ProcessedItem[] {
		const chain: ProcessedItem[] = [];

		const grabParent = (item: ProcessedItem, chain: ProcessedItem[]): ProcessedItem[] => {
			if (item.parent) {
				return grabParent(item.parent, [ item.parent, ...chain ]);
			}
			return chain;
		}

		return grabParent(item, [ item ]);
	}
}