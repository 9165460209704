import { IconButton, Modal } from "rsuite";
import CommonIcon from "../components/common/CommonIcon";
import { useLocation, useNavigate } from "react-router";
import { useApp } from "../App";

export default function OCMModal() {

	const app = useApp();

	const onClose = () => {
		app.showModal("");
	}

	const placeCall = () => {
		window.location.href = "tel:+447966303977";
	}

	return (
		<Modal open={(app.currentModal == "ocm")} size="xs" backdrop="static" onClose={onClose}>
            <Modal.Header>Call On Call Manager</Modal.Header>
            <Modal.Body>
                Would you like to call the On Call Manager?
            </Modal.Body>
            <Modal.Footer>
                <IconButton
                    icon={<CommonIcon icon="phone" />}
                    onClick={placeCall}
                >Call</IconButton>
                <IconButton
                    appearance='primary'
                    icon={<CommonIcon icon="close" />}
                    onClick={onClose}
                >Cancel</IconButton>
            </Modal.Footer>
        </Modal>
	);
}