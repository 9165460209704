import Styled from 'styled-components';
import CommonIcon from './common/CommonIcon';
import { IconButton, Modal, Tooltip, Whisper } from 'rsuite';
import { useLocation, useNavigate } from 'react-router';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useApp } from '../App';

const HeaderContainer = Styled.div`
	width: 100%;
	padding: 20px;
	background-color: #008295;
	background: linear-gradient(rgb(0, 137, 150), rgb(0, 110, 120));

	position: sticky;
	top: 0;

	z-index: 100;

	.rs-btn {
		color: white !important;
	}

	.flex {
		flex: 33.3%;
	}
	.title {
		text-align: center;
		font-size: 23pt;
	}
`;

interface State {
	clickTime?: Date,
}

export default function Header() {

	const [state, setState] = useState<State>({
	});

	const location = useLocation();
	const navigate = useNavigate();
	const app = useApp();

	const goUp = useCallback(() => {
		const path = location.pathname.substring(1);
		const ex = path.split("/");

		ex.splice(ex.length-1, 1);
		const finalPath = ex.join("/");

		navigate(finalPath);
	}, [ location ]);

	const openSearch = () => {
		navigate("/search");
	}

	const showBack = () => {
		if (location.pathname === "/") {
			return false;
		}
		return true;
	}

	const showOCMPrompt = () => {
		app.showModal("ocm");
	}

	const handleBrandclick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (ev.button !== 0) {
			return;
		}
		console.log("click");
		const now = new Date();

		if (state.clickTime) {
			// Second click

			const elapsed = now.getTime() - state.clickTime.getTime();
			console.log("Elapsed", elapsed);

			if (elapsed < 400) {
				console.log("Double clicked");
				app.showModal("version");
			}

			setState(s => ({
				...s,
				clickTime: undefined,
			}));
		} else {
			// First Click
			setState(s => ({
				...s,
				clickTime: now,
			}));
		}
	}, [ state.clickTime ]);

	return (
		<HeaderContainer>
			{showBack() && <IconButton
				icon={<CommonIcon icon="chevronLeft"/>}
				size="lg"
				appearance="subtle"
				onClick={goUp}
				style={{
					position: "absolute",
				}}
			/>}
			{!showBack() && <Whisper
				defaultOpen
				placement="bottom"
				speaker={<Tooltip>Call OCM</Tooltip>}
			>
				<IconButton
					icon={<CommonIcon icon="phone" />}
					
					size="lg"
					appearance="primary"
					color="red"

					onClick={showOCMPrompt}

					style={{
						position: "absolute",
					}}

				/>
			</Whisper>}
			<div className="title" onClick={handleBrandclick}>
				Bridgeway Help
			</div>

			<IconButton
				icon={<CommonIcon icon="search" />}
				size="lg"
				appearance="subtle"
				style={{
					position: "absolute",
					right: "20px",
					top: "20px"
				}}
				onClick={openSearch}
			/>
			
		</HeaderContainer>
	);
}