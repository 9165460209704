import { Button } from 'rsuite';
import Styled from 'styled-components';

const PromptContainer = Styled.div`
	background-color: #008295;
	position: fixed;
	left: 50%;
	bottom: 50px;

	transform: translateX(-50%);

	width: 80%;
	max-width: 550px;

	padding: 20px;
	border-radius: 15px;

	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 13pt;
	box-shadow:10px 10px 10px rgba(17, 17, 17, .5);

	z-index: 100;

	.prompt {
		flex: 1;
		padding-bottom: 20px;
	}
	.buttons {

	}
`;

interface Props {
	onConfirm: () => void,
	onCancel: () => void,
}


export default function InstallPrompt(props: Props) {

	return (
		<PromptContainer>
			<div className="prompt">
				<h4>Install Bridgeway Help?</h4>
				Did you know you can install Bridgeway Help on your phone?
			</div>
			<div className="buttons">
				<Button onClick={props.onConfirm} appearance="primary" size="lg" block>Install</Button>
				<Button onClick={props.onCancel} size="lg" block>No Thanks</Button>
			</div>
		</PromptContainer>
	);
}